import React from "react"
import { Helmet } from "react-helmet"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../components/layout"
// import { space } from "../theme"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  text-align: justify;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 960px;
  }
  p {
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  ul,
  ol {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  .alpha {
    list-style-type: lower-latin;
  }
  .clean {
    list-style-type: none;
  }
  ul li,
  ol li {
    line-height: 1.5rem;
  }
  ul ul,
  ol ul,
  ul ol,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  blockquote {
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5rem;
    margin-bottom: 0;
    line-height: 1.5rem;
    font-weight: bold;
  }
  h1 {
    font-size: 3rem;
    line-height: 4.5rem;
    margin-top: 3rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 2rem;
  }
  h3 {
    font-size: 1.414rem;
  }
  h4 {
    font-size: 0.707rem;
  }
  h5 {
    font-size: 0.4713333333333333rem;
  }
  h6 {
    font-size: 0.3535rem;
  }
  /* Tables */
  table {
    margin: 0 auto;
    margin-top: 1.5rem;
    border-spacing: 1px;
    border-collapse: collapse;
  }
  table td,
  table th {
    padding: 1rem;
    line-height: 33px;
    border: 1px solid black;
  }
  /* Code blocks */
  code {
    vertical-align: bottom;
  }
  /* Leading paragraph text */
  .lead {
    font-size: 1.414rem;
  }
  /* Hug the block above you */
  .hug {
    margin-top: 0;
  }
  strong {
    font-weight: bold;
  }
`

const PrivacyPolicy = () => (
  <Layout>
    <Helmet>
      <title>Paybase - Privacy policy</title>
      <meta property="og:title" content="Paybase - Privacy policy" />
    </Helmet>
    <Wrapper>
      <Container>
        <h2>PRIVACY AND COOKIE POLICY</h2>

        <p>
          Paybase GmbH (<strong>Paybase</strong>) is committed to protecting
          and respecting your privacy. This policy, together with any agreement
          we enter into with you, sets out how your personal data will be
          treated, your privacy rights and how the law protects you.
        </p>
        <p>
          Paybase (<strong>our, us, we, data controller</strong>) provides a
          full range of payment services and is responsible for your personal
          data. Our details are included below, and the purposes for which we
          act as a data controller are detailed in the table under the section
          titled “Purposes for which we will use your personal data”.
        </p>
        <p style={{ textAlign: "center" }}>
          <strong>Paybase GmbH</strong>
          <br />
          Hauptstrasse 66
          <br />
          12159 Berlin
          <br />
          Germany
          <br />
          dpo@paybase.io
          <br />
          <a target="_blank" href="www.paybase.io">
            www.paybase.io
          </a>
        </p>
        <p>
          Please read the following carefully to understand our views and
          practices regarding your personal data and how we will treat it. By
          providing consent you are accepting and consenting to the practices
          described in this policy.
        </p>

        <h3>INFORMATION WE COLLECT FROM YOU</h3>
        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed
          <strong>(anonymous data).</strong>
        </p>
        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together follows:
        </p>
        <ul>
          <li>
            <strong>Identity Data</strong> includes information about your
            identity such as name, address, date of birth, username or similar
            identifier, title, gender.
          </li>
          <li>
            <strong>Contact Data</strong> includes where you live and how to
            contact you including: address, email address and telephone numbers.
          </li>
          <li>
            <strong>Financial Data</strong> includes information about your
            financial position, status and history, and payment account details
            (can include bank, credit, payment and electronic money accounts).
          </li>
          <li>
            <strong>Transaction Data</strong> includes details about payments to
            and from your accounts with us.
          </li>
          <li>
            <strong>Technical Data</strong> includes details about the devices
            and technology you use including internet protocol (IP) address,
            your login data, browser type and version, time zone setting and
            location, browser plug-in types and versions, operating system and
            platform and other technology on the devices you use to access our
            website or services.
          </li>
          <li>
            <strong>Profile Data</strong> includes your username and password,
            purchases or orders made by you, your interests, preferences,
            feedback and survey responses.
          </li>
          <li>
            <strong>Usage Data</strong> includes information about how you use
            our website, products and services.
          </li>
          <li>
            <strong>Marketing and Communications Data</strong> includes your
            preferences in receiving marketing from us and our third parties and
            your communication preferences.
          </li>
          <li>
            <strong>Special Types of Data</strong> include racial or ethnic
            origin, racial or ethnic origin, religious or philosophical beliefs,
            trade union membership, genetic and bio-metric and health data. We
            will only process such data if the law allows us to do so.
          </li>
        </ul>

        <h3>IF YOU FAIL TO PROVIDE PERSONAL DATA</h3>
        <p>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you and if you fail to provide that data when
          requested, we may not be able to meet our regulatory obligations or
          perform the contract we have or are trying to enter into with you (for
          example, to provide you with goods or services). If you do not provide
          the requested information we may have to decline your application or
          cancel a product or service you have with us but we will notify you if
          this is the case at the time.
        </p>

        <h3>HOW YOUR PERSONAL DATA IS COLLECTED</h3>
        <p>
          We use different methods to collect data about you (and your business)
          including through:
        </p>
        <ul>
          <li>
            <strong>Information you give us:</strong>
            <ul>
              <li>when you apply for, or use, our products or services;</li>
              <li>
                when you communicate with us whether by phone, post, email,
                online or by other means;
              </li>
              <li>when you use our websites or device applications;</li>
              <li>when you apply for an account;</li>
              <li>in emails, letters and other correspondence; and</li>
              <li>
                when you request marketing to be sent to you by us or selected
                third parties;
              </li>
            </ul>
          </li>
          <li>
            <strong>Your use of our services</strong>. The data we collect when
            you use our services (such as making transactions, viewing your
            account details or using any part of our website) includes:
            <ul>
              <li>Transaction Data;</li>
              <li>Profile Data;</li>
              <li>Usage Data; and</li>
              <li>Technical Data</li>
            </ul>
          </li>
          <li>
            <strong>Third parties.</strong> We may receive personal data about
            you from various third parties and public sources as set out below:
            <ul>
              <li>Companies that introduce you to us;</li>
              <li>Card associations;</li>
              <li>
                Public information sources such as Companies House and the
                Electoral Register;
              </li>
              <li>Government and law enforcement agencies; and</li>
              <li>
                Agents working on our behalf including fraud prevention
                agencies, analytics providers, advertisers.
              </li>
            </ul>
          </li>
        </ul>

        <h3>HOW WE USE YOUR DATA</h3>
        <p>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>
        <ul>
          <li>Where you consent to it;</li>
          <li>
            Where we need to perform the contract we are about to enter into or
            have entered into with you;
          </li>
          <li>
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests; and
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation.
          </li>
        </ul>

        <h3>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</h3>
        <p>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate. We have also included which data
          controllers will use, and be responsible for, your data in the manner
          specified.
        </p>
        <p>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </p>
        <table>
          <thead>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
              <th>
                Lawful basis for processing including basis of legitimate
                interest
              </th>
            </tr>
          </thead>
          <tr>
            <td>
              To register you as a new customer, to verify your identity, to
              open an account, provide services to you and manage our
              relationship with you (e.g. inform you of changes and correspond
              with you)
            </td>
            <td>
              <ul className="alpha">
                <li>Identity</li>
                <li>Contact</li>
                <li>Financial</li>
                <li>Transaction</li>
                <li>Marketing and Communications</li>
              </ul>
            </td>
            <td>
              <ol>
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation </li>
                <li>
                  Necessary for our legitimate interests (e.g. risk management
                  and fraud prevention)
                </li>
                <li>Consent </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To enable you to use your account to make and receive payment
              transactions.
            </td>
            <td>
              <ul className="alpha">
                <li>Identity</li>
                <li>Contact</li>
                <li>Financial</li>
                <li>Transaction</li>
              </ul>
            </td>
            <td>
              <ol>
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation </li>
                <li>
                  Necessary for our legitimate interests (e.g. risk management
                  and fraud prevention)
                </li>
                <li>Consent </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To administer and protect our business, your account and our
              website, to improve our website and products/services (including
              troubleshooting, data analysis, testing, system maintenance,
              support, security, reporting, complying with our regulatory
              obligations and hosting of data)
            </td>
            <td>
              <ul className="alpha">
                <li>Identity</li>
                <li>Contact</li>
                <li>Technical</li>
                <li>Transaction</li>
                <li>Usage</li>
              </ul>
            </td>
            <td>
              <ol>
                <li>
                  Necessary for our legitimate interests (for running our
                  business, provision of administration and IT services, network
                  security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise)
                </li>
                <li>Necessary to comply with a legal obligation </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To give you, or allow selected third parties to give you,
              information about goods and services we think you may be
              interested in.
            </td>
            <td>
              <ul className="alpha">
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Usage</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
              </ul>
            </td>
            <td>
              <ol>
                <li>Consent</li>
                <li>
                  Necessary for our legitimate interests (to study how customers
                  use our products/services, to develop them, to grow our
                  business,to inform our marketing strategy to develop new
                  products/services and grow our business)
                </li>
              </ol>
            </td>
          </tr>
        </table>

        <br />
        <h3>MARKETING</h3>
        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
        </p>

        <h3>PROMOTIONAL OFFERS FROM US</h3>
        <p>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you or your business. This is how we decide which
          products, services and offers may be relevant for you or your business
          (we call this marketing).
        </p>
        <p>
          You will receive marketing communications from us if you have
          requested information from us or purchased services from us and, in
          each case, you have not opted out of receiving that marketing.
        </p>

        <h3>THIRD-PARTY MARKETING</h3>
        <p>
          We will get your express opt-in consent before we share your personal
          data with any company outside our group of companies for marketing
          purposes.
        </p>

        <h3>OPTING OUT</h3>
        <p>
          You can ask us to stop sending you marketing messages or sharing your
          data with third parties for marketing purposes at any time by
          contacting us using the details provided above.
        </p>

        <h3>COOKIES</h3>

        <p>
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and also allows us to improve our site.
        </p>
        <p>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree.
        </p>
        <p>
          Cookies contain information that is transferred to your computer's
          hard drive.
        </p>
        <p>We use the following cookies:</p>

        <ul>
          <li>
            <strong>Strictly necessary cookies.</strong> These are cookies that
            are required for the operation of our website. They include, for
            example, cookies that enable you to log into secure areas of our
            website, use a shopping cart or make use of e-billing services.
          </li>
          <li>
            <strong>Analytical/performance cookies.</strong> They allow us to
            recognise and count the number of visitors and to see how visitors
            move around our website when they are using it. This helps us to
            improve the way our website works, for example, by ensuring that
            users are finding what they are looking for easily.
          </li>
          <li>
            <strong>Functionality cookies.</strong> These are used to recognise
            you when you return to our website. This enables us to personalise
            our content for you, greet you by name and remember your preferences
            (for example, your choice of language or region)
          </li>
          <li>
            <strong>Targeting cookies.</strong> These cookies record your visit
            to our website, the pages you have visited and the links you have
            followed. We will use this information to make our website and the
            advertising displayed on it more relevant to your interests. We may
            also share this information with third parties for this purpose.
          </li>
        </ul>

        <p>
          You can find more information about the individual cookies we use and
          the purposes for which we use them in the table below:
        </p>

        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Name</th>
              <th>Purpose</th>
              <th>More information</th>
            </tr>
          </thead>
          <tr>
            <td>Intercom</td>
            <td>
              <ul>
                <li>intercom-id-[xxx],</li>
                <li>intercom-lou-[xxx],</li>
                <li>gtm_id, </li>
                <li>product_store_identifier</li>
              </ul>
            </td>
            <td>
              <p>
                Intercom is our partner that provides the in-website chat
                functionality.
              </p>
              <p>
                These cookie is used by Intercom as a session so that you, as a
                user, can continue a chat as you move through the site and to
                track the different users across sessions.
              </p>
            </td>
            <td style={{ wordBreak: "break-word" }}>
              <a
                target="_blank"
                href="https://www.intercom.com/terms-and-policies#privacy"
              >
                https://www.intercom.com/terms-and-policies#privacy
              </a>
            </td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>
              <ul>
                <li>_ga</li>
                <li>_gat</li>
                <li>_gid</li>
              </ul>
            </td>
            <td>
              <p>
                Set by Google Analytics to collect anonymous information about
                you, the user, and how you use our website. Typical information
                it collects would include how many visitors we get to the
                website and how long visitors stay on our website.
              </p>
              <p>
                The _ga cookie is specifically used to store the Client ID. The
                _ga cookie is essential to us as we use it to identify unique
                users. This cookie expires after 2 years.
              </p>
              <p>
                The _gid cookie is used to distinguish between users and has an
                expiration time of 24-hours.
              </p>
              <p>The _gat cookie is used to throttle request rate.</p>
              <p>
                Throttling is a process that is used to control the usage of
                APIs by consumers during a given period. Throttling limit is
                considered as cumulative at API level. We use throttling to
                limit the number of API requests per day/week/month.
              </p>
            </td>
            <td style={{ wordBreak: "break-word" }}>
              <a
                target="_blank"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id"
              >
                https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
              </a>
            </td>
          </tr>
          <tr>
            <td>Crazy Egg</td>
            <td>
              <ul>
                <li>ceg.s</li>
                <li>ceg.u</li>
                <li>ceir</li>
              </ul>
            </td>
            <td>
              <p>
                The Crazy Egg Script stores cookies on your computer used by you
                whenever you visit our website. The cookies contain a cookie
                value that allows Crazy Egg to detect if you are a returning
                Visitor or a first-time Visitor. No Personally Identifiable
                Information relating to the you or details about content or
                statistics are stored within this cookies.
              </p>
              <p>
                We use Crazy Egg and the ceg.s, ceg.u and ceir cookies for
                heatmap tracking. A heatmap is a graphical representation of
                data that uses a system of color-coding to represent different
                values. We use heatmap tracking to show your behaviour on our
                website. We track where you and other users have clicked on a
                page and how far you have scrolled down a page.
              </p>
            </td>
            <td style={{ wordBreak: "break-word" }}>
              <a target="_blank" href="https://www.crazyegg.com/terms">
                https://www.crazyegg.com/terms
              </a>
            </td>
          </tr>
          <tr>
            <td>Paybase</td>
            <td>
              <ul>
                <li>_paybase.tkn</li>
              </ul>
            </td>
            <td>
              Paybase Console authorisation cookie containing refreshable short
              lived tokens.
            </td>
            <td></td>
          </tr>
        </table>
        <br />
        <p>
          Please note that third parties (including, for example, advertising
          networks and providers of external services like web traffic analysis
          services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting
          cookies.
        </p>
        <p>
          You can block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          site.
        </p>

        <h3>CHANGE OF PURPOSE</h3>
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </p>
        <p>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </p>
        <p>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>

        <h3>WHO WE MAY DISCLOSE YOUR INFORMATION TO</h3>

        <p>You agree that we may disclose your personal information to:</p>
        <ul>
          <li>
            Any member of our group of companies, which means our subsidiaries,
            our ultimate holding company and its subsidiaries as reasonably
            necessary for the purposes, and on the legal basis, detailed in this
            policy;
          </li>
          <li>
            Allied Irish Banks plc and First Merchant Processing (Ireland)
            Designated Activity Company who we work with in order to provide a
            full range of payment services;
          </li>
          <li>
            Our agents, advisors and business partners who we use to help manage
            your accounts and services, improve services and resolve issues such
            as legal disputes;
          </li>
          <li>HM Revenue & Customs, regulators and other authorities;</li>
          <li>
            Fraud prevention and credit reference agencies for the purpose of
            verifying your identity and ensuring the security of your account;
          </li>
          <li>
            Any party linked with you or your business including ; organisations
            that introduce you to us;
          </li>
          <li>Organisations we introduce you to for marketing purposes;</li>
          <li>
            Analytics and search engine providers that assist us in the
            improvement and optimisation of our website;
          </li>
          <li>
            Card scheme providers such as Visa, MasterCard, Maestro where the
            card scheme rules require us to do so or to any regulatory body as
            required under any applicable law or regulations;
          </li>
          <li>Any insurance company for the purposes of insuring risk; and</li>
          <li>
            Other entities where we are obliged to by law or to law enforcement
            agencies for the purposes of registration of fraud or suspected
            fraud or where we have your consent.
          </li>
        </ul>
        <p>
          You agree that we can and will disclose your personal information to
          third parties:
        </p>
        <ul>
          <li>
            In the event that we sell or buy any business or assets, in which
            case we will disclose your personal data to the prospective seller
            or buyer of such business or assets;
          </li>
          <li>
            If substantially all of our assets are acquired by a third party, in
            which case personal data held by it about its customers will be one
            of the transferred assets;
          </li>
          <li>
            If we are under a duty to disclose or share your personal data in
            order to comply with any legal obligation, or in order to enforce or
            apply our terms of use and other agreements; or to protect the
            rights, property, or safety of us, our customers, or others. This
            includes exchanging information with other companies and
            organisations for the purposes of fraud protection and credit risk
            reduction.
          </li>
          <li>
            In addition to the disclosures detailed in this section, we may also
            disclose your personal information where it is necessary to do so:
            for compliance with a legal obligation; in order to protect the
            vital interests of you or another natural person; and for the
            establishment, exercise or defence of legal claims.
          </li>
        </ul>

        <h3>HOW WE USE YOUR INFORMATION TO MAKE AUTOMATED DECISIONS</h3>

        <p>
          We may use systems to make automated decisions based on information we
          hold about you or your business. This helps us to make sure our
          decisions are quick, fair, efficient and correct, based on what we
          know. These automated decisions can affect the products, services or
          features we may offer you now or in the future. The following are some
          of the ways we may use systems to make automated decisions:
        </p>

        <p>
          <strong>Tailoring products and services</strong>
        </p>

        <p>
          We may use your data along with that of other customer in order to
          study and learn about our customers’ needs, and to make decisions
          based on what we learn. This helps us to design products and services
          for different customer groups, and to manage our relationships with
          them.
        </p>

        <p>
          <strong>Fraud detection</strong>
        </p>

        <p>
          We use your personal information to help determine if your account, or
          transaction, is being used for fraud or money laundering. If there is
          a risk of fraud, or money laundering, we may suspend or terminate your
          transaction and/or account and refuse access to your account.
        </p>

        <p>
          <strong>Opening accounts</strong>
        </p>

        <p>
          We use automated checks to help determine if you satisfy our criteria
          for an account, including fraud, money laundering and eligibility
          checks.
        </p>

        <p>
          <strong>Your rights with respect to automated decisions</strong>
        </p>
        <ul>
          <li>
            You can ask that we do not make our decision based on the automated
            decision alone.
          </li>
          <li>
            You can object to an automated decision, and ask that a person
            reviews it.
          </li>
          <li>
            If you want to know more about these rights, or to exercise them,
            please contact us.
          </li>
        </ul>
        <br />

        <h3>WHERE WE STORE AND TRANSFER YOUR PERSONAL DATA</h3>

        <p>
          All information you provide to us will be transferred and stored in a
          jurisdiction located in the European Economic Area
          <strong>(EEA)</strong>. However, we may transfer some or all of your
          data to countries outside of the EEA which may not offer the same
          level of protection as the GDPR with respect to the processing of your
          personal data. Where your data is transferred to such countries it
          will be protected by appropriate safeguards, namely the use of
          standard data protection clauses adopted or approved by the European
          Commission
          <strong>(EC)</strong> can can by found on the EC’s website:
        </p>
        <a
          target="_blank"
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
        </a>
        <p>
          Where data is to be transferred to a group company in a country
          outside of the EEA which may not offer the same level of protection as
          the GDPR with respect to the processing of your personal data., we
          will ensure that the group company agrees to binding corporate rules
          in accordance with Article 47 of the GDPR which can be found at:
        </p>
        <a
          target="_blank"
          href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679"
        >
          https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679
        </a>
        <p>
          Where we transfer data to organisations based in the US, we may
          transfer data to them if they are part of the Privacy Shield which
          requires them to provide similar protection to personal data shared
          between the Europe and the US. For further details, see European
          Commission:
        </p>
        <a
          target="_blank"
          href="https://ec.europa.eu/info/strategy/justice-and-fundamental-rights/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"
        >
          https://ec.europa.eu/info/strategy/justice-and-fundamental-rights/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en
        </a>

        <h3>DATA SECURITY</h3>
        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </p>

        <h3>KEEPING AND DELETING YOUR DATA</h3>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>We keep certain data for minimum periods, namely:</p>
        <ul>
          <li>
            we retain Transaction Data for a minimum of seven years after the
            date of the transaction;
          </li>
          <li>
            we retain certain Account Information, for a minimum of seven years
            after your agreement with us has ended;
          </li>
        </ul>
        <p>
          The majority of personal data we hold will be deleted seven years
          after the termination of any agreement you have with us unless it is
          needed for other necessary or legitimate purposes.
        </p>
        <p>
          In some circumstances you can ask us to delete your data: see request
          erasure below for further information.
        </p>
        <p>
          In some circumstances we may anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes in which case we may use this information indefinitely
          without further notice to you.
        </p>

        <h3>YOUR RIGHTS</h3>
        <p>Under the GDPR you have the right to:</p>
        <ul>
          <li>
            <strong>Request access to your personal data</strong> (commonly
            known as a "data subject access request"). This enables you to
            receive a copy of the personal data we hold about you and to check
            that we are lawfully processing it. Please note that we also have to
            consider our regulatory obligations and the rights of other
            individuals when providing you with information and that this may
            mean that the information we provide is redacted or partial in some
            instances.
          </li>
          <li>
            <strong>
              Request correction of the personal data that we hold about you.
            </strong>
            This enables you to have any incomplete or inaccurate data we hold
            about you corrected, though we may need to verify the accuracy of
            the new data you provide to us.
          </li>
          <li>
            <strong>Request erasure of your personal data.</strong> This enables
            you to ask us to delete or remove personal data where there is no
            good reason for us continuing to process it. You also have the right
            to ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.
          </li>
          <li>
            <strong>Object to processing of your personal data </strong>where we
            are relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you
            want to object to processing on this ground as you feel it impacts
            on your fundamental rights and freedoms. You also have the right to
            object where we are processing your personal data for direct
            marketing purposes. In some cases, we may demonstrate that we have
            compelling legitimate grounds to process your information which
            override your rights and freedoms.
          </li>
          <li>
            <strong>
              Request restriction of processing of your personal data.
            </strong>
            This enables you to ask us to suspend the processing of your
            personal data in the following scenarios: (a) if you want us to
            establish the data's accuracy; (b) where our use of the data is
            unlawful but you do not want us to erase it; (c) where you need us
            to hold the data even if we no longer require it as you need it to
            establish, exercise or defend legal claims; or (d) you have objected
            to our use of your data but we need to verify whether we have
            overriding legitimate grounds to use it.
          </li>
          <li>
            <strong>
              Request the transfer of your personal data to you or to a third
              party.
            </strong>
            We will provide to you, or a third party you have chosen, your
            personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </li>
          <li>
            <strong>
              Withdraw consent at any time where we are relying on consent to
              process your personal data.
            </strong>
            However, this will not affect the lawfulness of any processing
            carried out before you withdraw your consent. If you withdraw your
            consent, we may not be able to provide certain products or services
            to you. We will advise you if this is the case at the time you
            withdraw your consent.
          </li>
        </ul>
        <p>
          If you wish to exercise any of these rights you will need to contact
          us using the contact details above. In the case of exercising your
          right to access, you will also need to provide appropriate evidence of
          your identity.
        </p>
        <p>
          If you decide to exercise one or more of these rights please note that
          it may mean that we have to terminate any agreement we have with you
          as we may no longer be able to provide the service you requested.
        </p>

        <h3>CHANGES TO OUR PRIVACY POLICY</h3>
        <p>
          Any changes we make to our privacy policy in the future will be posted
          on this page
          <a target="_blank" href="https://paybase.io/privacy-policy">
            https://paybase.io/privacy-policy
          </a>
          . Please check back frequently to see any updates or changes to our
          privacy policy.
        </p>

        <h3>CONTACT</h3>
        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to the contact details at the start
          of this policy.
        </p>
      </Container>
    </Wrapper>
  </Layout>
)

export default PrivacyPolicy
